import React from 'react'
import AboutComponent from '../Components/SubComponents/About/AboutComponent'

const AboutPage = () => {
  return (
    <div>
      <AboutComponent/>
    </div>
  )
}

export default AboutPage
